<template>
  <section class="tw-bg-red-100">
    <div class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16 tw-pt-9">
      <div class="md:tw-grid md:tw-grid-cols-2 md:tw-gap-8 tw-text-left">
        <div class="tw-col-1 tw-justify-center tw-flex tw-flex-col">
          <h4 class="tw-text-black tw-text-2rem tw-leading-relaxed tw-font-semibold">
            <!--
            Download the mobile app for an even better experience.
            -->
            {{ $t('general.download_app') }}
          </h4>
          <div class="tw-mt-8 tw-flex tw-space-x-4">
            <a href="#">
              <img src="@/assets/img/landing/play_store.svg" alt="">
            </a>
            <a href="#">
              <img src="@/assets/img/landing/app_store.svg" alt="">
            </a>
          </div>
        </div>
        <div class="tw-col-1">
          <img class="tw-rounded-lg tw-object-cover tw-object-center"
               src="@/assets/img/landing/mobileapp-export-new-min.png" alt="">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CoupleApps',
}
</script>
