<template>
  <div>
    <section class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16">
      <div class="md:tw-grid md:tw-grid-cols-2 md:tw-gap-8 tw-text-left">
        <div class="tw-col-span-1 tw-justify-center tw-flex tw-flex-col">
          <h1 class="tw-font-serif tw-text-red tw-text-4xl sm:tw-text-4rem">Mango & Lola</h1>
          <h2 class="tw-mt-3 tw-font-serif tw-text-gray-n3 tw-text-lg sm:tw-text-2xl">For Couples</h2>
          <p class="tw-my-6 tw-w-3/4 tw-text-black">Creating your account on <span
              class="tw-font-serif tw-text-red">Mango & Lola</span> is fast, easy and free.</p>
          <router-link
            class="tw-inline-flex tw-no-underline tw-self-start tw-items-center tw-px-14 tw-py-4 tw-border tw-bg-red tw-leading-4 tw-font-semibold tw-rounded-md tw-text-white"
            :to="{ name: 'register', query: { type: 1 } }">
            {{ $t('auth.sign_up') }}
          </router-link>
        </div>
        <div class="tw-col-span-1 tw-mt-4 sm:tw-mt-0">
          <img class="tw-rounded-lg tw-object-cover tw-object-center" src="@/assets/img/landing/couples/1.jpg" alt="">
        </div>
      </div>
    </section>

    <section class="tw-bg-red-100 tw-mt-8 sm:tw-mt-40 tw-text-left tw-py-16">
      <div class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16">
        <div class="md:tw-grid md:tw-grid-cols-8 md:tw-gap-x-8">
          <div class="tw-col-span-6">
            <h3 class="tw-font-semibold tw-text-2rem tw-text-black">For couples</h3>
            <p class="tw-mt-6 tw-mb-3 tw-text-black">
              <span class="tw-font-serif tw-text-red">Mango & Lola’s</span> user-friendly platform was designed by an
              award-winning wedding planner to help you spend time on what matters most, enjoying the wedding of your
              dreams. We teamed up with the best software engineers and industry professionals available to create our
              top-notch online wedding platform.
            </p>
            <p class="tw-mb-0 tw-text-black">
              You can quickly see each vendor’s current pricing and available dates up-front to use in choosing the best
              one for you based on their ratings with the added ability to immediately book them online with the click
              of a
              button. We simplified the wedding planning process for busy couples who want to use a single
              source for all their wedding needs.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16 tw-mt-20">
      <AppFeat :feats="feats" />
    </section>

    <section class="tw-bg-red-100 tw-mt-20 tw-text-left tw-py-16 tw-text-black">
      <div class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16">
        <h3 class="tw-font-semibold tw-text-2rem tw-leading-relaxed tw-mb-4">Benefits</h3>
        <div class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-6 tw-gap-x-6 tw-gap-y-7">
          <benefit-box class="tw-col-span-2"
            text="Free private account with all wedding vendors and invoices in one place">
            <gift-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2"
            text="Avoid wasting time with vendors who are unavailable on your wedding date">
            <calendar-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2" text="Avoid wasting time calling vendors who are not in your price range">
            <clock-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2 sm:tw-col-start-2"
            text="Your wedding account data is stored on secure cloud servers">
            <lock-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2"
            text="Payment information is fully encrypted along with your personal data">
            <archive-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
        </div>
      </div>
    </section>

    <section class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 tw-px-4 lg:tw-px-16 tw-py-20">
      <div class="md:tw-grid md:tw-grid-cols-8 md:tw-gap-x-8 ">
        <div class="tw-col-span-6">
          <h3 class="tw-font-semibold tw-text-2rem tw-leading-relaxed tw-mb-4">Join the <span
              class="tw-font-serif tw-text-red tw-font-normal">Mango & Lola</span> Wedding Community!</h3>
          <p class="tw-mb-0 tw-leading-relaxed">
            We are passionate about weddings and have helped many couples successfully plan their weddings with
            confidence and ease. Start creating your dream wedding today knowing that we built our online platform to
            make the planning process as easy as possible so you can have more fun and enjoy everything your wedding day
            was meant to be.
          </p>
        </div>
      </div>
    </section>

    <couple-apps />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GiftIcon, CalendarIcon, ClockIcon, LockIcon, ArchiveIcon } from 'vue-feather-icons'
import CoupleApps from "@/components/landing/CoupleApps";
import BenefitBox from "@/components/landing/BenefitBox";
import AppFeat from "@/components/ApplicationFeatures";
import i18n from '@/i18n';

export default {
  components: {
    BenefitBox,
    CoupleApps,
    GiftIcon,
    CalendarIcon,
    ClockIcon,
    LockIcon,
    ArchiveIcon,
    AppFeat,
  },
  layout: 'basic',
  // middleware: ['auth'],
  metaInfo() {
    return {
      title: i18n.t('navbar.couples'),
      meta: [
        {
          name: 'description',
          content: i18n.t('description.couples')
        }
      ],
    }
  },

  data: () => ({
    dialogInvestor: false,
    dialogLegal: false,
    dialogJobs: false,
    dialogMarketing: false,
    dialogFaq: false,
    dialogContact: false,
    title: process.env.VUE_APP_TITLE,
    legals: [
      {
        title: 'Privacy Policy',
        description: ''
      },
      {
        title: 'Terms and Conditions',
        description: ''
      },
      {
        title: 'Copyright Policy',
        description: ''
      },
    ],
    faqs: [
      {
        title: 'How can I book a Vendor on M&L? ',
        description: 'If you’re ready to book a wedding professional on Mango & Lola when browsing Vendor’s offerings, you will encounter either “Auto-Booking” or “Booking Request”. '
      },
      {
        title: 'Auto-Booking?',
        description: 'Couples can book a Vendor instantly (without vendor approval), as long as the wedding is in the Vendor’s “area of coverage”, couples can book them “Auto-Booking”. You will be able to see the booking immediately in your account under “My Vendors”. '
      },
      {
        title: 'Booking request?',
        description: 'With this booking option, a Vendor has 48 hours to accept the job. Mango and Lola allows 48 hours for the vendor to reply, but most do reply within a few hours. Once a Vendor approves your booking request, it will appear in your account under “My Vendors”. '
      },
      {
        title: 'Custom quote?',
        description: 'If you would like to hire a Vendor, but do not see what you are looking for in their offerings, then you can submit a Custom Quote. This Vendor will create a custom quote for you, and you will be able to find it in your account under “quote requests”. You will have 48 hours to accept the quote offer, or quote offer will expire. Once you accept your quote offer, it will become a booking, and you will be able to see it in your account under “My Vendors”.'
      },
      {
        title: 'Customer service question? ',
        description: 'If you have a question, please contact us at support@mangolola.com'
      },
      {
        title: 'Account management question?',
        description: 'For couple accounts please contact us at caccounts@mangolola.com. For vendor accounts please contact us at vaccounts@mangolola.com'
      },

    ],
    articles: [
      {
        src: 'https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
        title: 'Mobile first & Responsive',
        text: 'Phasellus lorem enim, luctus ut velit eget, convallis egestas eros. Sed ornare ligula eget tortor tempor, quis porta tellus dictum.',
      },
      {
        src: 'https://images.unsplash.com/photo-1475938476802-32a7e851dad1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
        title: 'Think outside the box',
        text: 'Nam ut leo ipsum. Maecenas pretium aliquam feugiat. Aenean vel tempor est, vitae tincidunt risus. Sed sodales vestibulum nibh.',
      },
      {
        src: 'https://images.unsplash.com/photo-1416339442236-8ceb164046f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1892&q=80',
        title: 'Small changes, big difference',
        text: 'Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
      },
    ],
    features: [
      {
        icon: 'mdi-account-group-outline',
        title: 'Full service online',
        text: 'An easy to navigate wedding platform giving couples tools to browse/book all wedding pros.',
      },
      {
        icon: 'mdi-update',
        title: 'Transparency',
        text: 'Vendor\'s available dates & rates displayed. Option to directly book on site.',
      },
      {
        icon: 'mdi-shield-outline',
        title: 'Secure online booking site',
        text: 'All payments are kept entirely safe giving you peace of mind. Quick and easy online booking. Everything is handled online. Removing the opportunity for double bookings.',
      },
      {
        icon: 'mdi-shield-outline',
        title: 'PLATFORM FOR (PARTNERS) VENDORS',
        text: 'Showcase best work with photos & client reviews. Manage rates and availability. Stay up to date with reservations. Process invoices and payments.',
      },
    ],
    stats: [
      ['24k', 'Vendors'],
      ['330+', 'Booking per day'],
      ['1m', 'Views'],
      ['5m', 'Comments'],
    ],
    feats: [
      "Search Vendors by: Date, Location, Vendor Category",
      "Filter by price & rating",
      "Access vendor Calendars to see their availability",
      "View current vendor pricing unlike other online platforms",
      "Book Vendors directly on Mango & Lola",
      "See all vendor invoices and payments on one screen",
      "Use our expense tracking feature to help manage vendor costs",
    ],
  }),
  mounted() {
    ///  this.$http.get("sanctum/csrf-cookie");
  },
  computed: mapGetters({
    authenticated: 'auth/authenticated',
    user: 'auth/user',
    check: 'auth/check',

  })
}

</script>
