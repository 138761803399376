<template>
  <div class="tw-flex tw-flex-col tw-py-8 tw-px-6 tw-bg-white tw-rounded-md tw-border tw-border-gray-n1 tw-justify-center tw-items-center">
    <slot></slot>
    <h5 class="tw-mt-7 tw-text-center tw-leading-relaxed">
      {{ text }}
    </h5>
  </div>
</template>

<script>
export default {
  name: 'BenefitBox',
  props: ['text']
}
</script>

